<template>
	<div class="position-relative">
		<div class="position-absolute select-icon">
            <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0 d-flex align-items-center" title="Actualizar" v-on:click="getData()" tabindex="-1" :disabled="loading">
            	<span class="material-icons">refresh</span>
            </button>
        </div>
		<select :class="[classSelect, {'custom-select border':true}]" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" style="padding-left: 42px;" :disabled="loading || !hiddeElement">
			<option value="" disabled selected>
                <span v-if="loading">Cargando...</span>
                <span v-else>{{titleDF}}</span>
            </option>
			<option v-for="(data, index) in datos" :value="data.idMarca" :key="index">
				<span v-if="loading">Cargando...</span>
                <span v-else>{{data.descripcion}}</span>
			</option>
		</select>
	</div>
</template>
<script type="text/javascript">
	export default {
		props: ['modelValue', 'title', 'classSelect'],
    	emits: ['update:modelValue'],
		data(){
			return {
				loading: false,
				hiddeElement: true
			}
		},
		mounted(){
			this.getData()
            if(this.datos.length==1){
                this.hiddeElement = false
                this.$emit('update:modelValue', this.datos[0].idMarca)
            }else{
                this.hiddeElement = true
            }
		},
		methods: {
			getData(){
				this.loading = true
				this.$store.dispatch('vehiculosGetMarcas').then(res=>{
					if(res.data.length==1){
	                    this.hiddeElement = false
	                    this.$emit('update:modelValue', res.data[0].idMarca)
	                }else{
	                    this.hiddeElement = true
	                }
				}).catch(err => {
					return err
				}).then(() => {
					this.loading = false
				})
			}
		},
		computed: {
	        datos() {
	        	return (this.$store.getters.marcas || []).sort((a, b)=> a.descripcion - b.descripcion)
	        },
	        titleDF(){
	        	return this.title || 'Seleccionar marca'
	        }
	    },
	    watch:{
	    	'datos': {
	    		handler(val){
	    			if(val.length==1){
		                this.hiddeElement = false
		                this.$emit('update:modelValue', val[0].idMarca)
		            }else{
		                this.hiddeElement = true
		            }
	    		},
	    		deep: true
	    	}
	    }
	}
</script>